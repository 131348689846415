.letter {
    text-align: center;
    line-height: 32px;
    vertical-align: middle;
    margin-right: 0;
}

@media (prefers-color-scheme: dark) {
    .item {
        --background: #121212;
        --ion-item-border-color: #222222;
        --border-width: 0 0 1px 0;
        border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))))
    }

    .item:last-of-type {
        --border-width: 0;
    }
}

.item {
    overflow: visible;
    --inner-padding-end: 0;
    z-index: 1;
}

.room::part(content) {
    overflow: visible;
}