@font-face {
    font-family: '5x7';
    font-style: normal;
    font-weight: 400;
    src: url(/public/assets/fonts/5x7-dot-matrix.ttf) format('truetype');
}

.alarmDisplay {
    font-size: 18px;
    text-align: center;
    position: relative;
    background: yellowgreen;
    color: black;
    font-family: '5x7', monospace;
    -webkit-font-smoothing: subpixel-antialiased;
}

.alarmDisplayBackground {
    padding: 4px 8px 8px 8px;
    opacity: 0.1;
}

.alarmDisplayContent {
    padding: 4px 8px 8px 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

@media (prefers-color-scheme: dark) {
    .alarmDisplay {
        background: #728f02;
    }
}

.keypad ion-button {
    height: 64px;
    --border-radius: 12px;
    --background: lightgrey;
    --color: black;
}

.button {
    height: 2.5em;
    --padding-start: 12px;
    --padding-end: 10px;
}