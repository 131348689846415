.itemTitle {
    padding-right: 50px;
}

.itemTimestamp {
    align-self: flex-start;
    line-height: 1.8rem;
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 12px;
}

.listItem {
    z-index: 0;
}

.listItem p {
    white-space: pre-wrap;
}