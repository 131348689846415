.chargePointListItem {
    --padding-start: 0;
    --inner-padding-end: 0;
}

.actionChip {
    margin-right: 0;
    line-height: normal;
}

.actionChip.charging {
    --background:  var(--ion-color-success, #2dd36f);
    --color: white;
}

.actionChip.complete {
    --background: #59ac69;
    --color: white;
}

.actionChip.button {
    --background: var(--ion-color-primary, #3880ff);
    --color: var(--ion-color-primary-contrast, #fff);
    text-transform: uppercase;
}

.list .listImage {
    margin-right: 12px;
}

.list, .list ion-item {
    background: transparent;
    --background: transparent;
}

.list p {
    margin-bottom: 2px;
}

.alertLongText {
    height: 300px;
}

.detailSmallMiddle {
    font-size: 80%;
    vertical-align: top;
    line-height: 1.9em;
}

.inlineAvatar {
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    margin-top: -2px;
}