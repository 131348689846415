.panel {
    position: relative;
    padding: 0.5rem !important;
    border-radius: 10px;
    width: 48px;
    height: 48px;
}

.panel .temperature {
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: none;
}

.panel.off {
    opacity: 0.5;
    filter: grayscale(100%);
}

.toggle {
    padding-inline-end: 14px;
}

@media (max-width: 576px) {
    .panel {
        padding: 0.25rem !important;
        width: 36px;
        height: 36px;
    }

    .panel .temperature {
        font-size: 22px;
    }
}