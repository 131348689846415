.cameraContainer {
    margin: 0 auto;
    width: 480px;
    height: 270px;
    overflow: hidden;
    background: grey;
}

.camera {
    object-fit: cover;
    object-position: 0% 0%;
    width: 960px;
    height: 540px;
}