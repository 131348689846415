.slider {
    pointer-events: none;
    padding-top: 8px;
}

.slider:not([disabled])::part(knob),
.slider:not([disabled])::part(pin),
.slider:not([disabled]) ion-button {
    pointer-events: auto;
}

.slider::part(bar-active) {
    left: 0;
}
