.guides ion-row {
    hesight: 75px;
}

.guides ion-button {
    height: 56px;
}

.avatar {
    border-radius: 50%;
    overflow: hidden;
    width: 28px;
    height: 28px;
}

.banner {
    max-height: 382px;
}